import React, { useState } from 'react'
import "./Intro.css"
import { useNavigate } from 'react-router-dom'
import SelectCity from '../../SearchCities/Search/SelectCity/SelectCity';
import calendarIcon from '../../../assets/calendar.svg'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
const Intro = ({ backgroundImage }) => {
  const { t , i18n} = useTranslation();
  const language = i18n.language;
  const navigate = useNavigate();
  const [city, setCity] = useState('');
  const [date, setDate] = useState('');
  const handleCustomInputInChange = (date) => {
    setDate(date || null);
  };
  const handleMoveInDateChange = (date) => {

    setDate(date || null); 

  };
  const CustomInput = ({ value, onClick, onChange, name }) => (
    <div className="input-datepicker" onClick={onClick}>
      <input
        type="text"
        name={name}
        className='Select-country-container w-100'
        value={value}
        placeholder={t('intro.depart')} 

        onChange={onChange}
      />
      <span className="calendar-icon">
        <img src={calendarIcon} alt="Calendar" />
      </span>
    </div>
  );
  const handleClick = () => {
    const formattedDate = date ? format(new Date(date), 'yyyy-MM-dd') : '';
    const searchParams = new URLSearchParams({ city });
    if (formattedDate) {
      searchParams.append('date', formattedDate);
    }
    const url = `/${language}/search-cities?${searchParams.toString()}`;
    navigate(url);
  };
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  return (
    <div className='Intro-container d-block py-md-5 py-4 container-fluid mb-md-5 mb-4' style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className='Content-container'>
        <h2>{t('home.slogan_banner')}</h2>
        <div className='Input-container d-flex justify-content-between'>
          <div className='input-group input-country'>
            <SelectCity text={t('intro.destination')} onChange={(selectedValue) => setCity(selectedValue.value)} alert={true} />
          </div>
          <div className='input-group'>
            <DatePicker
              placeholder={t('intro.depart')} 
              selected={date}
              name="date"
              dateFormat="dd/MM/yyyy"
              onChange={handleMoveInDateChange}
              customInput={
                <CustomInput
                  value={date}
                  onChange={handleCustomInputInChange}
                  name="date"
                />
              }
              minDate={tomorrow}
            />
          </div>
          <button className='Search-btn' onClick={handleClick}>{t('intro.btn_search')}</button>
        </div>
      </div>
    </div>
  )
}

export default Intro
