import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { blogPosts, blogContent } from "../../Data/Data";
import './BlogContent.css';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import { useTranslation } from 'react-i18next';

const BlogContent = () => {
    const { id } = useParams();
    const {i18n}=useTranslation();
    const language=i18n.language;
    const [post, setPost] = useState(null);
    const [content, setContent] = useState(null);
    useEffect(() => {
        // Vérifier que id est un nombre valide
        const blogId = parseInt(id);
    
        if (isNaN(blogId)) {
          // Gérer le cas où l'ID n'est pas valide
          console.error('ID invalide');
          return;
        }
    
        // Trouver le post et le contenu basés sur l'ID
        const foundPost = blogPosts.find(blog => blog.id === blogId);
        const foundContent = blogContent.find(content => content.id === blogId);
    
        // Mettre à jour l'état avec les données trouvées
        setPost(foundPost);
        setContent(foundContent);
      }, [id, blogPosts, blogContent]); // Dépendances de useEffect
    
      if (!post || !content) {
        // Gérer le cas où les données ne sont pas encore chargées
        return <div>Chargement...</div>;
      }

    return (
        <>
        <Breadcrumbs 
         path={`/blog`}
         customRoutes={[
           {
             path: `/blog-content/${id}`,
             label: `${post.title}`
           }
         ]}/> 
        <div className='blog-content-section container mt-5 mb-5'>
            <h2 className='text-center'>{post.title}</h2>
            <div className='d-flex align-items-center justify-content-center mb-4 gap-2'>
                <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.56445 1.6106C3.56445 1.38003 3.75137 1.19312 3.98194 1.19312H19.5816C19.8122 1.19312 19.9991 1.38003 19.9991 1.6106V17.1168C19.9991 17.3474 19.8122 17.5343 19.5816 17.5343H3.98194C3.75137 17.5343 3.56445 17.3474 3.56445 17.1168V14.7313C3.56445 14.5007 3.75137 14.3138 3.98194 14.3138C4.21251 14.3138 4.39942 14.5007 4.39942 14.7313V16.6993H19.1642V5.60592H3.98194C3.75137 5.60592 3.56445 5.41901 3.56445 5.18844V1.6106ZM19.1642 4.77095V2.02808H4.39942V4.77095H19.1642Z" fill="#00BFFF" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.61426 0C7.84483 0 8.03174 0.186914 8.03174 0.417484V2.80549C8.03174 3.03606 7.84483 3.22297 7.61426 3.22297C7.38369 3.22297 7.19678 3.03606 7.19678 2.80549V0.417484C7.19678 0.186914 7.38369 0 7.61426 0Z" fill="#00BFFF" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.0005 0C16.2311 0 16.418 0.186914 16.418 0.417484V2.80549C16.418 3.03606 16.2311 3.22297 16.0005 3.22297C15.7699 3.22297 15.583 3.03606 15.583 2.80549V0.417484C15.583 0.186914 15.7699 0 16.0005 0Z" fill="#00BFFF" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.7891 0C12.0196 0 12.2065 0.186914 12.2065 0.417484V2.80549C12.2065 3.03606 12.0196 3.22297 11.7891 3.22297C11.5585 3.22297 11.3716 3.03606 11.3716 2.80549V0.417484C11.3716 0.186914 11.5585 0 11.7891 0Z" fill="#00BFFF" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.99618 4.76099C4.22675 4.76099 4.41366 4.9479 4.41366 5.17847C4.41366 8.54122 3.49639 10.9997 2.5694 12.6234C2.15194 13.3546 1.7333 13.9151 1.39718 14.3122H15.8426C15.8506 14.3046 15.8589 14.2966 15.8677 14.2882C15.9578 14.2012 16.0895 14.0673 16.2492 13.8853C16.5685 13.5214 16.9988 12.9657 17.4306 12.2094C18.293 10.699 19.165 8.38191 19.165 5.17847C19.165 4.9479 19.3519 4.76099 19.5825 4.76099C19.8131 4.76099 20 4.9479 20 5.17847C20 8.54122 19.0827 10.9997 18.1557 12.6234C17.6929 13.4341 17.2285 14.0351 16.8768 14.436C16.701 14.6365 16.5531 14.787 16.4475 14.889C16.3947 14.9399 16.3525 14.9788 16.3225 15.0056C16.3075 15.019 16.2956 15.0294 16.287 15.0369L16.2766 15.0458L16.2733 15.0486L16.2721 15.0496C16.2719 15.0497 16.2713 15.0503 16.0038 14.7297L16.2713 15.0503C16.1962 15.1129 16.1016 15.1471 16.0038 15.1471H0.417505C0.241771 15.1471 0.0848518 15.0371 0.0249896 14.8719C-0.0347705 14.7069 0.0149703 14.5223 0.149402 14.4096L0.150092 14.4091L0.154883 14.4049C0.159812 14.4007 0.167999 14.3935 0.179227 14.3835C0.201686 14.3634 0.236301 14.3317 0.281349 14.2882C0.371456 14.2012 0.503207 14.0673 0.662836 13.8853C0.982149 13.5214 1.41249 12.9657 1.84429 12.2094C2.70663 10.699 3.57869 8.38191 3.57869 5.17847C3.57869 4.9479 3.76561 4.76099 3.99618 4.76099Z" fill="#00BFFF" />
                </svg></span>
                <p className="blog-date text-center d-block mb-0">{post.date}</p>
            </div>

            <div className="image-text-container">
                <img src={content.imageUrl} alt={post.title} className="blog-content-image" />
                <div
                    className="blog-full-content-text"
                    dangerouslySetInnerHTML={{ __html: content.content }}>
                </div>
            </div>
        </div>
        </>
    );
};

export default BlogContent;
