import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import "./App.css"
import Faq from "./components/HomePage/Faq/Faq";
import Footer from "./components/Footer/Footer";
import NavBar from './components/NavBar/NavBar';
import Homepage from './components/HomePage/Homepage';
import SearchCities from './components/SearchCities/SearchCities';
import Cms from './components/CMS/Cms';
import ErrorPage from './components/404/ErrorPage';
import Faqspage from './components/Faqspage/Faqspage';
import Apartment from './components/Apartment/Apartment';
import Contact from './components/Contact/Contact';
import BookingRoom from './components/BookingRoom/BookingRoom';
// import ProposeApartment from './components/ProposeApartment/ProposeApartment';
import CancelAlert from './components/CancelAlert/CancelAlert';
import BlogPage from './components/BlogPage/BlogPage';
import BlogContent from './components/BlogPage/BlogContent';
import { useTranslation } from 'react-i18next';

// import reactga from 'react-ga';
// reactga.initialize('G-1K58YXV1ZE');


function App() {
  // useEffect(() => {
  //   const TRACKING_ID = "G-1K58YXV1ZE"
  //   ReactGA.initialize(TRACKING_ID);

  // }, [])



  return (
    <div>
      <BrowserRouter>
        <NavBar />


        <Routes>
          {/* home routes */}
          <Route path="/" element={<Navigate to="/fr/" replace />} />
          <Route path="/fr" element={<Homepage />} />
          <Route path="/en" element={<Homepage />} />
          <Route path="/es" element={<Homepage />} />

          {/* faq routes */}
          <Route path="/faq" element={<Faqspage />} />
          <Route path="/fr/faq" element={<Faqspage />} />
          <Route path="/en/faq" element={<Faqspage />} />
          <Route path="/es/faq" element={<Faqspage />} />

          {/* blog routes */}
          <Route path="/blog" element={<BlogPage />}  />
          <Route path="/fr/blog" element={<BlogPage />} />
          <Route path="/en/blog" element={<BlogPage />} />
          <Route path="/es/blog" element={<BlogPage />} />

          {/* contact routes */}
          <Route path="/contact" element={<Contact />} />
          <Route path="/fr/contact" element={<Contact />} />
          <Route path="/en/contact" element={<Contact />} />
          <Route path="/es/contact" element={<Contact />} />
           
          {/* cms routes */}
          <Route path="/page/:slug" element={<Cms />} />
          {/* <Route path="/fr/page/:slug" element={<Cms />} />
          <Route path="/en/page/:slug" element={<Cms />} />
          <Route path="/es/page/:slug" element={<Cms />} /> */}

          {/* search routes */}
          <Route path="/search-cities" element={<SearchCities />} />
          <Route path="/fr/search-cities" element={<SearchCities />} />
          <Route path="/en/search-cities" element={<SearchCities />} />
          <Route path="/es/search-cities" element={<SearchCities />} />
          
          {/* booking routes */}
          <Route path="/booking-enquiry/:id" element={<BookingRoom />} />
          <Route path="/fr/booking-enquiry/:id" element={<BookingRoom />} />
          <Route path="/en/booking-enquiry/:id" element={<BookingRoom />} />
          <Route path="/es/booking-enquiry/:id" element={<BookingRoom />} />
          
          {/* apartment details routes */}
          <Route path="/apartment/:id" element={<Apartment />} />
          <Route path="/fr/apartment/:id" element={<Apartment />} />
          <Route path="/en/apartment/:id" element={<Apartment />} />
          <Route path="/es/apartment/:id" element={<Apartment />} />
          
          {/* blog content routes */}
          <Route path="/blog-content/:id" element={<BlogContent />} />
          <Route path="/fr/blog-content/:id" element={<BlogContent />} />
          <Route path="/en/blog-content/:id" element={<BlogContent />} />
          <Route path="/es/blog-content/:id" element={<BlogContent />} />
         

         
          {/* <Route path="/i-propose-an-apartment" element={<ProposeApartment/>}/> */}
          <Route path="/cancel-alert" element={<CancelAlert />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>

        <Faq />
        <Footer />

      </BrowserRouter>
    </div>
  );
}

export default App;
